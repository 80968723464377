
.App_Grid__1P4dW {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: auto auto auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;

}

@media only screen and (max-device-width: 480px) {
  .App_Grid__1P4dW {
    display: block;
    font-size: 16px;
  }
}

@media only screen and (max-device-width: 400px) {
  .App_Grid__1P4dW {
    display: block;
    font-size: 13px;
    font-weight: 300;
  }
}

.App_icon__3AMC6 {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}



.App_Child__28-r_ {
  min-height: 600px;
  max-width: 600px;
  min-width: 90%;
/*
  background: linear-gradient(
      16deg,
      rgb(11, 30, 56) 0%,
      rgb(10, 37, 73) 42%,
      rgb(8, 45, 95) 100%
    ),
    linear-gradient(to right, rgb(37, 62, 99), rgb(11, 31, 70));

  background-repeat: no-repeat;
  background-origin: padding-box, border-box;
  */
  border-radius: 0em;
  grid-column: 3;
  grid-row: 2;
  text-align: center;
  color: white;
  /*
  -webkit-box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44);
  box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44);
  */
}

@media only screen and (max-device-width: 400px) {
  .App_tab-list-item__5PrKy {
    min-width: none;
  }
}


.App_curtain__3HaZx {
  background-color: rgba(0, 0, 0, 0.466);
  position: static;
  height: 99em;
  width: auto;
  grid-column-start: 1;
  grid-column-end: 6;
  z-index: 2;
  filter: blur(8px);
  -webkit-backdrop-filter: blur(1.5em);
  backdrop-filter: blur(1.5em);
}


a {
/*  color: rgb(255, 88, 40); ARES*/
  color: #438d96;
  text-decoration: none;
}


.App_none__3opx9 {
 position: none;
}

.App_topframe__111Si {
  position: absolute;
  top: 1px;
  left: 1px;
  font-size: 0.3em;
}

.App_wallet__aX-RQ {
  padding-top: 0.5em;
  border-radius: 0 0 2em 2em;
}



.Navigation_navigation__3GlNX {
  grid-column: 3;
  grid-row: 2;
  position: absolute;
  margin-top: -2em;
  margin-left: 2em;

}

.Navigation_buttonActive__1jaAi {
  padding: 0.4em;
  font-weight: 600;
  color: rgb(58, 58, 58);
  background-color: #ffffff;
  border: none;
  width: 13em;
  height: 2em;
  border-radius: 3em 3em 0 0;
  margin-right: -1.5em;
  border: 0.3em solid rgba(8, 67, 134, 0.199);
  border-bottom: none;
  z-index: 1;
  position: relative;
  box-shadow: -10px 0px 13px 1px #00000031, 10px -10px 13px 5px #0000001f,
    0px -30px 50px 13px rgba(0, 0, 0, 0);
  cursor: pointer;
}
.Navigation_buttonNonActive__1n9Qi {
  padding: 0.5em;
  font-weight: 600;
  color: rgba(247, 247, 247, 0.726);
  background-color: #5781cfc0;
  border: none;
  width: 11.5em;
  height: 2em;
  border-radius: 3em 3em 0 0;
  margin-right: -1.5em;
  border: 0.2em solid rgba(37, 66, 99, 0.705);
  border-bottom: none;
  z-index: 0;
  position: relative;
  cursor: pointer;
}

.Navigation_buttonNonActive__1n9Qi:hover {
  background-color: #5781cfd7;
  color: rgb(247, 247, 247);
}

.Navigation_buttonActive__1jaAi:focus {
  outline: none;
}
.Navigation_buttonNonActive__1n9Qi:focus {
  outline: none;
}


.tab-list {
  border: 1px solid rgb(253, 85, 36);
  border: 1px solid #438d96;

  /*
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  */

  background: rgba(48, 16, 7);
  background: rgba(0, 16, 7);
  padding-left: 0;

  white-space: nowrap;


  width: 100%;
}

@media only screen and (max-device-width: 480px) {
  .tab-list {

    overflow-x: scroll;
    overflow-y: hidden;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    -webkit-overflow-scrolling: touch;

    position: fixed;
    bottom: 0;
    background: rgba(48, 16, 7);
    background: rgba(0, 6, 8);
    /*    background-color: black;*/
    height: 55px;
    margin: 0px;
  }
}


/* Hide scrollbar for IE, Edge and Firefox */
.tab-list {
}

.title-item {
  display: inline-block;
  vertical-align: middle;
  list-style: none;
  width: 18em;
  text-align: left;  
}
.title-item img {
  height: 48px;
}

.tab-list-item img {
}

@media only screen and (max-device-width: 480px) {
  .tab-list-item img {
    padding-bottom: 4px;
    height: 24px;
  }
  .title-item img {
    height: 36px;
  }
  
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 1rem 0.5rem;

  vertical-align: middle;

}

@media only screen and (max-device-width: 480px) {
  .tab-list-item {
    height: 100%;
    font-weight: 600;
    font-size: 16px;

    vertical-align: middle;
  }
}


.tab-list-active {
  background-color: #ffffff22;
  border-width: 1px 1px 0 1px;
  color: rgb(253, 85, 36);
  color: #438d96;
}




.Profile_info__17YQO {
  padding-top: 0.5em;

  border-radius: 0 0 2em 2em;
}

.Profile_info__17YQO p {
  padding-top: 1em;
  color: rgba(250,250,250, 0.7);
  font-size: 0.7em;
}

@media only screen and (max-device-width: 400px) {
  .Profile_info__17YQO p {
    font-size: 0.4em;
  }
}


.Profile_info__17YQO button {
  color: white;
  font-size: 1em;
  /* border: rgb(253, 85, 36) solid 0.05em; ARES*/
  border: #438d96 solid 0.05em; 


  /*  border: white solid 0.05em; */
  border-radius: 0.5em;
  height: 5em;
  width: 20em;
  cursor: pointer;
}

.Profile_info__17YQO button:hover {
  background-color: rgb(48, 16, 7);
}

.Profile_info__17YQO button:focus {
  outline: none;
}


.Profile_network__1sMxY p {
  color: rgb(253, 85, 36);
  color: #438d96;
  font-size: 0.8em;
}

.Profile_info__17YQO li {
  color: rgb(253, 85, 36);
  color: #438d96;
  font-size: 0.8em;
}

@media only screen and (max-device-width: 400px) {
  .Profile_info__17YQO li {
    font-size: 0.8em;
  }
}



.Profile_info__17YQO ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.Profile_special__UNFg5 h1 {
  margin-top: 1.2em;
}
.Profile_icon__fH2x_ {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}

.Profile_inputDiv__f_tou {
  padding-top: 0.1em;
  padding-bottom: 3em;
  height: 3em;
  align-items: center;
}


.Profile_trader__3ztaJ {
  height: 3em;
  width: 23em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}


.Profile_input__2Ou2V {
  height: 3em;
  width: 15em;
  border-radius: 0.5em;
  border: none;
  text-align: center;
}

input:focus {
  outline: none;
  border: 0.2em solid rgba(139, 60, 23, 0.301);
}

.Profile_totals__2nWlH {
  margin-top: 3em;
  height: 3em;
}

.Profile_go__2725W {
  cursor: pointer;
}

.Profile_goMax__2KhnP {
  padding-left: 1%;
  margin: 0;
}

.Profile_goMax__2KhnP h5 {
  font-size: 2em;
}


.Profile_box__1lHq8 div {
  width: 280px;
  margin: auto;
}

.Profile_box__1lHq8 p {
  color: rgb(253, 85, 36);
  color: #438d96;
  font-size: 0.8em;
}

.Profile_box__1lHq8 a {
  color: rgb(253, 85, 36);
  color: #438d96;
  text-decoration: none;
}


.Profile_boxInfo__1GVkB {
  border: #438d96 solid 0.05em;
  padding: 1em;
  border-radius: 0.5em;
}

.Profile_boxHigh__14GRC div {
  width: 280px;
  margin: auto;
}

.Profile_boxHigh__14GRC p {
  color: white;
}
/*  xfont-size: 0.8em;*/


.Profile_digButton__1GymQ {
  background-color: rgba(253,85,36);
  background-color: #438d96;
  color: white;
  font-size: 1em;
  border: rgb(253, 85, 36) solid 0.05em;
  border: #438d96 solid 0.05em;
  border-radius: 0.5em;
  height: 2.5em;
  width: 20em;
  margin-top: 1em;
  cursor: pointer;
}

.Profile_digButton__1GymQ:hover {
  background-color: rgb(48, 16, 7);
}


.Profile_digButton__1GymQ:focus {
  outline: none;
 }


.Profile_digButton__1GymQ p {
  display: inline;
  position: relative;
  /*
  bottom: 0.5em;
  */
}



 .Profile_blockButton__18Nel {
   background-color: rgba(253,85,36);
   background-color: #438d96;
   color: white;
   font-size: 1em;
   border: rgb(253, 85, 36) solid 0.05em;
   border: #438d96 solid 0.05em;
   border-radius: 0.5em;
   height: 3em;
   width: 12em;
   margin-top: 1em;
   cursor: pointer;
 }

 .Profile_blockButton__18Nel:hover {
   background-color: rgb(48, 16, 7);
 }


 .Profile_blockButton__18Nel:focus {
   outline: none;
  }
  .Profile_blockButton__18Nel p {
    display: inline;
    position: relative;
    bottom: 0.5em;
  }



  .Profile_digIcon__hSznm {
    height: 2em;
    position: relative;
    top: 0.1em;
    right: 1em;
  }


.Profile_checkButton__16oMO {
  background-color: rgba(200,200,200,0.1);
  color: white;
  font-size: 1em;
  border: rgb(253, 85, 36) solid 0.05em;
  border: #438d96 solid 0.05em;
  border-radius: 0.5em;
  height: 3em;
  width: 4em;
  margin-top: 1em;
  cursor: pointer;
}

.Profile_checkButton__16oMO:hover {
  background-color: rgb(48, 16, 7);
}

.Profile_checkButton__16oMO:focus {
  outline: none;
 }

 .Profile_checkButton__16oMO:disabled,
 .Profile_checkBbutton__2O8Ov[disabled]{
   color: rgba(200,200,200,0.9);
 }

.Profile_checkIcon__1IDbz {
  height: 2em;
  position: relative;
  top: 0.1em;
  right: 1em;
}

.Profile_checkButton__16oMO p {
  display: inline;
  bottom: 0.5em;
}




.Profile_allButton__weMJE {
  background-color: rgba(253,85,36);
  background-color: #438d96;
  color: white;
  font-size: 1em;
  border: rgb(253, 85, 36) solid 0.05em;
  border: #438d96 solid 0.05em;
  border-radius: 0.5em;
  height: 2.5em;
  width: 2.5em;
  margin-top: 1em;
  cursor: pointer;
}

.Profile_allButton__weMJE:hover {
  background-color: rgb(48, 16, 7);
}


.Profile_allButton__weMJE:focus {
  outline: none;
 }

.Profile_allButton__weMJE p {
   display: inline;
   position: relative;
 }



 .Profile_currencyButton__3Huwx {
   background-color: rgba(253,85,36);
   background-color: #438d96;
   color: white;
   font-size: 1em;
   border: rgb(253, 85, 36) solid 0.05em;
   border: #438d96 solid 0.05em;
   border-radius: 0.5em;
   height: 2.5em;
   width: 9em;
   margin-top: 1em;
   cursor: pointer;
 }

 .Profile_currencyButton__3Huwx:hover {
   background-color: rgb(48, 16, 7);
   background-color: rgb(0, 16, 7);
 }


 .Profile_currencyButton__3Huwx:focus {
   outline: none;
  }

 .Profile_currencyButton__3Huwx p {
    display: inline;
    position: relative;
  }



 .Profile_importButton__14hrH {
   background-color: rgb(48, 16, 7);
   background-color: rgb(0, 16, 7);
   color: white;
   font-size: 0.6em;
   border: rgb(253, 85, 36) solid 0.05em;
   border: #438d96 solid 0.05em;
   border-radius: 0.5em;
   height: 3em;
   width: 5em;
   margin-top: 0em;
   margin-left: 1em;
   cursor: pointer;
 }

 .Profile_importButton__14hrH:hover {
   background-color: rgba(253,85,36);
   background-color: #438d96;
 }


 .Profile_importButton__14hrH:focus {
   outline: none;
  }

 .Profile_importButton__14hrH p {
    display: inline;
    position: relative;
  }


 .Profile_moonButton__XCfUz {
   background-color: rgb(125,0,255); /*rgba(253,85,36);*/
   color: white;
   font-size: 1em;
   border: rgb(125, 0, 255) solid 0.05em;
   border-radius: 0.5em;
   height: 3em;
   width: 6em;
   margin-top: 1em;
   cursor: pointer;
 }

 .Profile_moonButton__XCfUz:hover {
   background-color: rgb(48, 0, 7);
 }


 .Profile_moonButton__XCfUz:focus {
   outline: none;
  }

 .Profile_moonButton__XCfUz p {
    display: inline;
    position: relative;
  }



 .Profile_rampButton__1WJW_ {
   background-color: #21bf73;
   color: white;
   font-size: 1em;
   border: #21bf73 solid 0.05em;
   border-radius: 0.5em;
   height: 2.5em;
   width: 9em;
   margin-top: 1em;
   cursor: pointer;
 }

 .Profile_rampButton__1WJW_:hover {
   background-color: #10af63;
 }


 .Profile_rampButton__1WJW_:focus {
   outline: none;
  }

 .Profile_rampButton__1WJW_ p {
    display: inline;
    position: relative;
  }



 .Profile_scanButton__1NQPv {
   background-color: rgba(253,85,36);
   background-color: #438d96;
   color: white;
   font-size: 1em;
   border: rgb(253, 85, 36) solid 0.05em;
   border: #438d96 solid 0.05em;
   border-radius: 0.5em;
   height: 3em;
   width: 6em;
   margin-top: 1em;
   margin-bottom: 1em;
   cursor: pointer;
 }

 .Profile_scanButton__1NQPv:hover {
   background-color: rgb(48, 16, 7);
 }


 .Profile_scanButton__1NQPv:focus {
   outline: none;
  }

 .Profile_scanButton__1NQPv p {
    display: inline;
    position: relative;
  }


.Profile_larger__1RZOg {
  font-size: 1.2em;
}

.Profile_stone__2poDO {
  position: relative;
  top: 2em;
}

.Profile_animatestone__3xu8f
{
  background-image: linear-gradient(
    50deg,
    #574315 0%,
    #7a2110 20%,
    #ff1361 30%,
    #fff800 40%,
    #ffffff 40%,
    #7a2110 80%,
    #574315 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: Profile_textclip__STbcs 2s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@keyframes Profile_textclip__STbcs {
  to {
    background-position: -200% center;
  }
}

/*padding-top: 10em;*/

.Profile_header__1QvVH {
  height: 7em;

  background-image: url(/static/media/aresheader.62a4f4a1.png);
  background-image: url(/static/media/skylandheader.c604a48a.png);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 50%;

}




.Profile_logo__1Y2zB {
  padding-top: 0.5em;
  background-image: url(/static/media/ares1500x.b4cd15bc.png);
  background-image: url(/static/media/skyland1500x.60ce72a0.png);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;

  border-radius: 0 0 2em 2em;
}

.Profile_logo__1Y2zB p {
  padding-top: 1em;
  color: rgba(250,250,250, 0.7);
  font-size: 0.7em;
}

@media only screen and (max-device-width: 400px) {
  .Profile_logo__1Y2zB p {
    font-size: 0.4em;
  }
}

.Profile_logo__1Y2zB .Profile_xx__3DJ0n {
  display: flex;
  justify-content: center;
  padding-top: 25%;
  padding-bottom: 25%;
}



.Profile_infologo__ME3-8 {
/*
  background-image: url("../assets/ares1500x30.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  */
  padding-left: 1em;
  padding-right: 1em;
}



.Profile_connectButton__9StAP {
  background-color: rgba(253,85,36);
  background-color: #438d96;
  color: white;
  font-size: 1em;
  border: rgb(253, 85, 36) solid 0.05em;
  border: #438d96 solid 0.05em;
  border-radius: 0.5em;
  height: 2.5em;
  width: 12em;
  margin-top: 1em;
  cursor: pointer;
}

.Profile_connectButton__9StAP:hover {
  background-color: rgb(48, 16, 7);
  background-color: rgb(0, 16, 7);
}

.Profile_connectButton__9StAP:focus {
  outline: none;
 }

.Profile_warning__8pXon {
  font-weight: 700;
  font-size: 1em;
}



.Profile_connect__32tAh a {
}

.Profile_connect__32tAh img {
  height: 2.6em;
}

.Profile_connect__32tAh button {
  background-color: rgba(0,0,0,0);
  color: white;
  font-size: 1em;
  border: rgb(253, 85, 36) solid 0.05em;
  border: #438d96 solid 0.05em;
  border-radius: 0.5em;
  height: 3.5em;
  width: 12em;
  height: 5em;
  margin-top: 1em;
  cursor: pointer;
}

.Profile_connectlogo__1tEZp {
   width: 3em;
}

.Profile_connecttitle__XDGQc {
  width: 17em;
  vertical-align: middle;
  height: 2.6em;
  padding-left: 2em;
}


.Profile_dbgText__3ZQD5 {
  font-size: 0.5em;
  color: grey;
}
.Profile_dbgText__3ZQD5 button {
  background-color: rgba(0,0,0,0);
  color: #438d96;
  font-size: 0.5em;
  border: #438d96 solid 0.05em;
  border-radius: 0.5em;
  height: 2em;
  width: 12em;
  cursor: pointer;
}

.Profile_adviceBox__2hyRH {
  border: 0.05em solid #438d96;
  padding: 1em;
  border-radius: 0.5em; 
}

.Profile_adviceBox__2hyRH p {
  margin: 0;
}

.Profile_pseudoButton__2Qg47 {
  padding-left: 2.3em;
}

.Profile_inputUnit__3nnmu {
  display:inline-block;
  width: 4em;
  min-width: 4em;  
}
.Profile_inputWallet__2b1kH {
  display:inline-block;
  width: 28em;
  min-width: 28em;  
  border-radius: 0.5em;
  border: 1px solid black;
  text-align: center;
}

.Profile_Signature__3nK6Z {
  padding: 0.1em;
  border: 1px solid black;
  border-radius: 0.5em;
  margin:1em;
}


.Profile_Formular__339Qe,
.Profile_wrapper__1nppb {
  display: flex;
  flex: 1 1 auto;
}



.Profile_Formular__339Qe  {
  flex-direction: column;
  border: none;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

.Profile_Formular__339Qe input {
  padding: 1em;
  margin: 0.5em;
  border-radius: 0.5em;
  border: 1px solid black;
  vertical-align: middle;
}

.Profile_Formular__339Qe select {
  padding: 0.5em;
  margin: 0.4em;
  width: 5em;
  border-radius: 0.5em;
  vertical-align: middle;
}

.Profile_boxOrder__Ri4TL {
  background-color: white;
  border-radius: 0.5em;
  border: 1px solid black;

}
.Profile_boxOrder__Ri4TL input {
  border-color: black;
  background-color: white;
}
.Profile_boxOrder__Ri4TL button {
  background-color:white;
  color: black;
  margin: 2em;
}
.Profile_boxOrder__Ri4TL p {
  color: black;
}
.Profile_boxOrder__Ri4TL span {
  color: black;
}
.Profile_boxOrder__Ri4TL h1 {
  color: black;
}
.Profile_boxOrder__Ri4TL h2 {
  color: black;
}
.Profile_boxOrder__Ri4TL h3 {
  color: black;
}

.Profile_contractOrder__9R2Vg p {
  text-align: left;
  margin: 1em;
}
.Profile_contractDisclaimer__3TPAc p {
  text-align: left;
  margin: 1em;
  font-size: 0.7em;
  color: black
}
/*
html, body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

background: #000;
*/

.Origin_logo__6GY63 {
  position: absolute;
  color: hsl(136, 100%, 85%);
  text-shadow:
    0 0 .5em #fff,
    0 0 .5em currentColor; 
}

.Origin_base__ndnk5 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Origin_rain__2jumy {
  display: flex;
}
.Origin_rain__2jumy p {
  line-height: 1;
}
.Origin_rain__2jumy span {
  display: block;
  width: 2vmax;
  height: 2vmax;
  font-size: 2vmax;
  color: #9bff9b11;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
}

* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
  background: black; /*linear-gradient(#472712, #030201);*/
  color: white;

  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
}

