.tab-list {
  border: 1px solid rgb(253, 85, 36);
  border: 1px solid #438d96;

  /*
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  */

  background: rgba(48, 16, 7);
  background: rgba(0, 16, 7);
  padding-left: 0;

  white-space: nowrap;


  width: 100%;
}

@media only screen and (max-device-width: 480px) {
  .tab-list {

    overflow-x: scroll;
    overflow-y: hidden;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    -webkit-overflow-scrolling: touch;

    position: fixed;
    bottom: 0;
    background: rgba(48, 16, 7);
    background: rgba(0, 6, 8);
    /*    background-color: black;*/
    height: 55px;
    margin: 0px;
  }
}


/* Hide scrollbar for IE, Edge and Firefox */
.tab-list {
}

.title-item {
  display: inline-block;
  vertical-align: middle;
  list-style: none;
  width: 18em;
  text-align: left;  
}
.title-item img {
  height: 48px;
}

.tab-list-item img {
}

@media only screen and (max-device-width: 480px) {
  .tab-list-item img {
    padding-bottom: 4px;
    height: 24px;
  }
  .title-item img {
    height: 36px;
  }
  
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 1rem 0.5rem;

  vertical-align: middle;

}

@media only screen and (max-device-width: 480px) {
  .tab-list-item {
    height: 100%;
    font-weight: 600;
    font-size: 16px;

    vertical-align: middle;
  }
}


.tab-list-active {
  background-color: #ffffff22;
  border-width: 1px 1px 0 1px;
  color: rgb(253, 85, 36);
  color: #438d96;
}
