
.Grid {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: auto auto auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;

}

@media only screen and (max-device-width: 480px) {
  .Grid {
    display: block;
    font-size: 16px;
  }
}

@media only screen and (max-device-width: 400px) {
  .Grid {
    display: block;
    font-size: 13px;
    font-weight: 300;
  }
}

.icon {
  height: 7em;
  margin-top: 2em;
  opacity: 0.8;
}



.Child {
  min-height: 600px;
  max-width: 600px;
  min-width: 90%;
/*
  background: linear-gradient(
      16deg,
      rgb(11, 30, 56) 0%,
      rgb(10, 37, 73) 42%,
      rgb(8, 45, 95) 100%
    ),
    linear-gradient(to right, rgb(37, 62, 99), rgb(11, 31, 70));

  background-repeat: no-repeat;
  background-origin: padding-box, border-box;
  */
  border-radius: 0em;
  grid-column: 3;
  grid-row: 2;
  text-align: center;
  color: white;
  /*
  -webkit-box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44);
  box-shadow: 0px 0px 800px 20px rgba(19, 75, 99, 0.44);
  */
}

@media only screen and (max-device-width: 400px) {
  .tab-list-item {
    min-width: none;
  }
}


.curtain {
  background-color: rgba(0, 0, 0, 0.466);
  position: static;
  height: 99em;
  width: auto;
  grid-column-start: 1;
  grid-column-end: 6;
  z-index: 2;
  filter: blur(8px);
  -webkit-backdrop-filter: blur(1.5em);
  backdrop-filter: blur(1.5em);
}


a {
/*  color: rgb(255, 88, 40); ARES*/
  color: #438d96;
  text-decoration: none;
}


.none {
 position: none;
}

.topframe {
  position: absolute;
  top: 1px;
  left: 1px;
  font-size: 0.3em;
}

.wallet {
  padding-top: 0.5em;
  border-radius: 0 0 2em 2em;
}


